servicesRouter = $.extend({}, routerBase);

servicesRouter.state = {
    type: "",
    //actual industry/practice of focus
    service: "",
}

servicesRouter.init = function (initialType) {
    window.onpopstate = this.popState;
    var stateFromURL = {
        type: getUrlParameter('type'),
        service: getUrlParameter('service'),
    }
    if (stateFromURL.type) {
        setIndustryOrPractice(stateFromURL.type);
    }
    else {
        stateFromURL.type = initialType;
    }
    if (stateFromURL.service) {
        setService(stateFromURL.service);
    }
    history.replaceState(stateFromURL, "");
    console.log('servicesRouter initialized');
}

servicesRouter.popState = function (ev) {
    if (ev.state) {
        if (ev.state.type != servicesRouter.state.type) {
            console.log('industry/practice changed');
            setIndustryOrPractice(ev.state.type);
        }
        //set service after type so setting type doesn't blow away service
        if (ev.state.service != servicesRouter.state.service) {
            console.log('service changed');
            setService(ev.state.service)
        }
        servicesRouter.state = ev.state;
    }
};

//************** News page (search) *********/

newsRouter = $.extend({}, routerBase);
newsRouter.state = {};
//the init call later on checks if this is set. if a search state comes in the url, it will initialize with the parameters in here
window.initialSearchState;

//ignores page number
newsRouter.isEmptySearch = function (stateObject) {
    return !(stateObject.keywords || stateObject.industries.length || stateObject.practices.length || stateObject.type);
}

newsRouter.popState = function (ev) {
    if (!ev.state) {
        console.log('got nothing');
        return;
    }
    $.filterManager.handlePopstate(ev.state, newsRouter.isEmptySearch(ev.state));
}

newsRouter.init = function () {
    window.onpopstate = this.popState;

    var stateFromURL = {
        keywords: getUrlParameter('keywords'),
        industries: getUrlParameter('industries').split("~").filter(String),
        practices: getUrlParameter('practices').split("~").filter(String),
        type: getUrlParameter('type'),
        page: parseInt(getUrlParameter('page')),
    }

    if (isNaN(stateFromURL.page)) stateFromURL.page = -1;

    //replace existing state so we have something to go back to
    history.replaceState(stateFromURL, "");
    if (!newsRouter.isEmptySearch(stateFromURL)) {
        console.log('setting initial state from url');
        window.initialSearchState = stateFromURL;
    }
    console.log('newsRouter initialized');
}

//************** intelligence page ********/
intelligenceRouter = $.extend({}, routerBase);
intelligenceRouter.state = {};

intelligenceRouter.isEmptySearch = function (stateObject) {
    return !(stateObject.keywords || stateObject.industries.length || stateObject.practices.length || stateObject.type || stateObject.page != -1);
}

intelligenceRouter.popState = function (ev) {
    if (!ev.state) {
        console.log('got nothing');
        return;
    }
    $.filterManager.handlePopstate(ev.state, intelligenceRouter.isEmptySearch(ev.state));
}

intelligenceRouter.init = function () {
    window.onpopstate = this.popState;

    //get state (if any) from url
    var stateFromURL = {
        keywords: getUrlParameter('keywords'),
        industries: getUrlParameter('industries').split("~").filter(String),
        practices: getUrlParameter('practices').split("~").filter(String),
        type: getUrlParameter('type'),
        page: parseInt(getUrlParameter('page')),
    };

    if (isNaN(stateFromURL.page)) stateFromURL.page = -1;

    //replace existing state so we have something to go back to
    history.replaceState(stateFromURL, "");
    if (!intelligenceRouter.isEmptySearch(stateFromURL)) {
        console.log('setting initial state from url');
        window.initialSearchState = stateFromURL;
    }
    console.log('intelligenceRouter initialized');
}

//************** results page ********/
resultsRouter = $.extend({}, routerBase);
resultsRouter.state = {};

resultsRouter.isEmptySearch = function (stateObject) {
    return !(stateObject.keywords || stateObject.industries.length || stateObject.practices.length || stateObject.type || stateObject.page != -1);
}

resultsRouter.popState = function (ev) {
    if (!ev.state) {
        console.log('got nothing');
        return;
    }
    $.filterManager.handlePopstate(ev.state, resultsRouter.isEmptySearch(ev.state));
}

resultsRouter.init = function () {
    window.onpopstate = this.popState;

    //get state (if any) from url
    var stateFromURL = {
        keywords: getUrlParameter('keywords'),
        industries: getUrlParameter('industries').split("~").filter(String),
        practices: getUrlParameter('practices').split("~").filter(String),
        type: getUrlParameter('type'),
        page: parseInt(getUrlParameter('page')),
    };

    if (isNaN(stateFromURL.page)) stateFromURL.page = -1;

    //replace existing state so we have something to go back to
    history.replaceState(stateFromURL, "");
    if (!resultsRouter.isEmptySearch(stateFromURL)) {
        console.log('setting initial state from url');
        window.initialSearchState = stateFromURL;
    }
    console.log('resultsRouter initialized');
}


//************** people search page ********/
peopleRouter = $.extend({}, routerBase);
peopleRouter.state = {};

//TODO: sort? page? 
peopleRouter.isEmptySearch = function (stateObject) {
    return !(stateObject.keyword || stateObject.letter || stateObject.industries.length || stateObject.practices.length || stateObject.offices.length || stateObject.level || stateObject.license || stateObject.language)
}

peopleRouter.popState = function (ev) {
    if (!ev.state) {
        console.log('got nothing');
        return;
    }
    console.log(ev.state);
    peopleRouter.state = ev.state;
    handlePeoplePopstate(ev.state, peopleRouter.isEmptySearch(ev.state), true);
}

peopleRouter.init = function () {
    window.onpopstate = this.popState;


    var stateFromURL = {
        keyword: getUrlParameter('keyword'),
        letter: getUrlParameter('letter'),
        industries: getUrlParameter('industries').split("~").filter(String),
        practices: getUrlParameter('practices').split("~").filter(String),
        offices: getUrlParameter('offices').split("~").filter(String),
        level: getUrlParameter('level'),
        license: getUrlParameter('license'),
        language: getUrlParameter('language'),
        sort: getUrlParameter('sort'),
        page: getUrlParameter('page')
    };
    if (isNaN(stateFromURL.page)) stateFromURL.page = 0;

    //replace existing state so we have something to go back to
    history.replaceState(stateFromURL, "");

    if (!peopleRouter.isEmptySearch(stateFromURL)) {
        console.log('setting initial state from url');
        window.initialSearchState = stateFromURL;
    }
}